<template>
  <div class="container">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card
        elevation="2"
        outlined
        shaped
        tile
        style="max-width: 100%;width:500px;"
      >
        <v-card-text>
          <div>
            <div class="text-center">
              <img
                class="mb-4"
                src="@/assets/logo.png"
                alt=""
                style="width:300px"
              />
            </div>
            <h4>Reset Password</h4>
            <h5>Enter the new password to reset.</h5>

            <v-text-field
              v-model="password"
              v-bind:label="$t('salesforce.Password')"
              :type="'password'"
              v-bind:placeholder="`Contraseña`"
              required
              :rules="passwordRequiredRules"
            ></v-text-field>

            <div class="checkbox mb-3"></div>

            <v-btn
              color="blue darken-1"
              block
              @click="onResetPassword(token, email, password)"
            >
              {{ $t("login.reset") }}
            </v-btn>

            <p class="mt-1 text-sm-center small">
              &copy; Trencadis Business Solutions, SL
            </p>
            <div class="text-right">
              {{ $t("login.try_login_again") }}
              <router-link to="/auth/login">{{
                $t("login.click_here")
              }}</router-link>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";

export default {
  name: "Resetpassword",
  props: ["token", "email"],
  data() {
    return {
      valid: true,
      password: null,
      passwordRequiredRules: [
        v => !!v || "El campo de contraseña es obligatorio"
      ]
    };
  },

  methods: {
    async onResetPassword(token, email, password) {
      let self = this;
      if (self.$refs.form.validate()) {
        try {
          const response = await ApiService.post("auth/resetpassword", {
            token,
            email,
            password
          });
          if (response && response.success) {
            logInfo(
              "The new password was saved successfully. Please try the new password"
            );
          } else {
            logError("The operation was failed. Please try again.");
          }
          this.$router.push({ name: "login" });
        } catch (error) {
          console.log(error);
        }
      }
    }
  },

  mounted() {
    // let self = this;
    // window.addEventListener("keyup", function(event) {
    //   if (event.keyCode === 13) {
    //     self.onSendEmail(self.email);
    //   }
    // });
  }
};
</script>

<style scoped>
.container {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
